import { NavLink } from "react-router-dom";
import "./ProjectPage.css";

const DemoVideo = ({ video, poster }) => {
  return (
    <video
      className="project-video"
      data-object-fit="cover"
      muted={true}
      loop={true}
      src={video}
      type="video/quicktime"
      poster={poster}
      playsInline=""
      autoPlay={true}
    >
      Sorry, your browser doesn’t support embedded videos.
    </video>
  );
}

const ProjectExternalLinks = ({ project }) => {
  return (
    <ul className="project-external-links">
      <li>
        <a className="project-external-link" target='_blank' rel="noreferrer" href={project.links.prod}>View {project.title}</a>
      </li>
    </ul>
  );
}

const PrevNextProjectLinks = ({ prev, next }) => {
  return (
    <div className="prev-next-links">
      {prev && (
        <NavLink onClick={()=> window.scrollTo(0, 0)} className="prev" to={`/projects/${prev.url}`}>
          {prev.title}
        </NavLink>
      )}
      {next && (
        <NavLink onClick={()=> window.scrollTo(0, 0)} className="next" to={`/projects/${next.url}`}>
          {next.title}
        </NavLink>
      )}
    </div>
  );
}


export const ProjectPage = ({ project, prev, next }) => {
  return (
    <div className="content project project-page-content">
      <h1 className="project-title">{`${project.title} - Built with ${project.technology}`}</h1>
      <h2 className="project-heading">{project.heading}</h2>

      <DemoVideo video={project.video.video} poster={project.video.poster} />

      <section className="project-summary">
        {project.summary.map((paragraph, index) => {
          return <p key={index}>{paragraph}</p>;
        })}
      </section>

      <ProjectExternalLinks project={project} />
      <PrevNextProjectLinks prev={prev} next={next} />
    </div>
  );
}
