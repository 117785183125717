import { NavLink } from "react-router-dom";
import "./Header.css";
import { Icon } from "../svg/Icon";

export function Header() {
  return (
    <header>
      <nav className="navigation">
        <ul>
          <li className="nav-link">
            <NavLink to="/">
              <span>
                <Icon />
              </span>
            </NavLink>
          </li>
        </ul>
      </nav>
    </header>
  );
}
