import { NavLink } from "react-router-dom";
import "./Homepage.css";
import { Hello } from "../../svg/Hello";

export const Homepage = () => {
  return (
    <div className="content introduction">
      <h1 className="introduction-heading">Hello, my name is Julienne.</h1>
      <p className="introduction-subheading">I am a web developer.</p>
      <p className="introduction-project-link">
        Check out <NavLink to={"/projects"}>my projects</NavLink>!
      </p>
      <div className="introduction-image">
        <Hello />
      </div>
    </div>
  );
};
