import { Routes, Route } from "react-router-dom";
import { projects } from "./contents/projects";
import { Header } from "./components/Header";
import { Homepage } from "./pages/Homepage/Homepage";
import { ProjectPage } from "./pages/ProjectPage/ProjectPage";
import "./App.css";
import { ProjectsPage } from "./pages/ProjectsPage/ProjectsPage";

function App() {
  return (
    <div className="app">
      <Header />

      <main>
        <Routes>
          <Route path="/" element={<Homepage />}></Route>
          <Route path="/projects" element={<ProjectsPage />}></Route>

          {projects.map((project, index) => {
            return (
              <Route
                path={`/projects/${project.url}`}
                key={index}
                element={
                  <ProjectPage
                    project={project}
                    prev={projects[index + 1]}
                    next={projects[index - 1]}
                  />
                }
              ></Route>
            );
          })}
        </Routes>
      </main>

      <footer className="copyright">Copyright &copy; 2021 Julienne San Luis</footer>
    </div>
  );
}

export default App;
