export const Hello = () => {
  return (
    <svg
      version="1.1"
      id="hello"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="300px"
      height="280px"
      viewBox="0 0 300 280"
    >
      <g id="hello_1_">
        <g id="hand">
          <path
            fill="none"
            stroke="hsl(265, 48%, 15%)"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
			M232.722,158.01c0,0-5.368-10.647-8.309-15.828c-3.095-6.019,1.398-12.26,8.309-5.795c6.911,6.464,2.677,9.362,7.356,9.809
			c4.682,0.445,9.362,14.043,6.911,17.387"
          />
          <path
            fill="none"
            stroke="hsl(265, 48%, 15%)"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
			M239.066,146.024c0,0,3.965-11.641,3.613-17.44s6.765-10.031,9.55-3.567c2.785,6.465-0.109,10.923,0,13.598"
          />
          <path
            fill="none"
            stroke="hsl(265, 48%, 15%)"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
			M247.664,142.182c0,0,13.508,2.011,18.497,5.573"
          />
          <path
            fill="none"
            stroke="hsl(265, 48%, 15%)"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
			M253.622,139.285c0,0,3.398-6.575,4.736-10.811c1.338-4.236,10.477-8.806,10.477,1.894c-1.113,8.248-2.897,12.929-2.897,12.929"
          />
          <path
            fill="none"
            stroke="hsl(265, 48%, 15%)"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
			M273.966,172.986c3.117-11.188,2.45-8.512,6.016-13.082c3.566-4.57,7.556-5.357,10.329-11.111
			c0.697-2.769-0.446-7.874-7.135-5.496c-3.801,2.228-7.876,7.803-7.876,7.803s3.121-6.688,5.351-13.821
			c2.229-7.133-7.579-11.39-10.924-3.742c-3.343,7.648-5.795,12.488-5.795,12.488"
          />
        </g>
        <g id="body">
          <path
            fill="none"
            stroke="hsl(265, 48%, 15%)"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
			M127.28,235.387c2.677,10.676,10.256,23.606,22.294,20.707c12.037-2.897,19.967-10.912,17.116-22.51"
          />
          <path
            fill="none"
            stroke="hsl(265, 48%, 15%)"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
			M95.289,226.488c-15.916,6.538-38.933,20.866-57.798,55.168"
          />
          <path
            fill="none"
            stroke="hsl(265, 48%, 15%)"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
			M79.131,268.131c-1.337,8.471-2.452,16.141-1.56,23.898"
          />

          <path
            display="none"
            fill="none"
            stroke="hsl(265, 48%, 15%)"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
			M230.494,260.774c0,0,6.687,12.039,7.355,29.426"
          />
          <path
            fill="none"
            stroke="hsl(265, 48%, 15%)"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
			M229.155,165.871c2.677,3.73,22.739,20.449,42.578,11.756"
          />
          <path
            fill="none"
            stroke="hsl(265, 48%, 15%)"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
			M209.913,197.388c0,0,20.803-17.086,22.809-40.827"
          />
          <path
            fill="none"
            stroke="hsl(265, 48%, 15%)"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
			M230.494,263.674c0,0,15.604-8.803,22.514-27.586c6.911-18.782,21.178-51.104,20.955-64.703"
          />
          <path
            fill="none"
            stroke="hsl(265, 48%, 15%)"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
			M230.939,263.674c0,0,3.641,11.295,4.83,24.966"
          />
        </g>
        <g id="face">
          <line
            fill="none"
            stroke="hsl(265, 48%, 15%)"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="108.111"
            y1="154.666"
            x2="110.786"
            y2="165.144"
          />

          <line
            fill="none"
            stroke="hsl(265, 48%, 15%)"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="176.993"
            y1="151.1"
            x2="178.554"
            y2="162.022"
          />
          <path
            fill="none"
            stroke="hsl(265, 48%, 15%)"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
			M138.428,183.646c0,0,9.363,9.139,16.72-1.338"
          />
          <path
            fill="none"
            stroke="hsl(265, 48%, 15%)"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
			M142.4,165.871c0,0,4.387-0.728,5.836-0.282"
          />
          <path
            fill="hsl(265, 48%, 15%)"
            stroke="hsl(265, 48%, 15%)"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
			M187.916,172.945c0,0-10.7,1.017-10.923,11.878c-0.224,10.859,8.024,10.637,10.923,11.305c2.898,0.67,15.604-3.566,13.82-12.929
			C199.953,173.837,193.712,172.723,187.916,172.945z"
          />

          <path
            fill="hsl(265, 48%, 15%)"
            stroke="hsl(265, 48%, 15%)"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
			M105.436,178.519c-9.824,2.078-13.151,6.241-11.814,11.814c1.337,5.573,1.784,9.139,10.923,10.923
			c9.14,1.783,12.038-4.235,13.375-8.472C119.257,188.55,112.025,177.125,105.436,178.519z"
          />
          <path
            fill="none"
            stroke="hsl(265, 48%, 15%)"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
			M211.769,174.803c0,1.292-0.019,8.936-0.097,10.558c-0.79,16.301-7.679,47.224-63.436,50.671
			c-54.694,1.232-64.244-17.887-67.08-26.528c-0.48-1.466-2.412-6.308-2.694-7.021"
          />
        </g>
        <g id="hair_1_">
          <path
            fill="none"
            stroke="hsl(265, 48%, 15%)"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
			M131.294,95.147c0,0,0,17.387-15.604,38.788"
          />
          <path
            fill="none"
            stroke="hsl(265, 48%, 15%)"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
			M100.531,105.401c0,0-2.675,18.725-13.821,35.667"
          />
          <path
            fill="none"
            stroke="hsl(265, 48%, 15%)"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
			M149.896,95.147c0,0,8.149,30.317,21.97,36.558"
          />
          <path
            fill="none"
            stroke="hsl(265, 48%, 15%)"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
			M178.554,99.159c0,0,5.351,20.509,23.183,29.425"
          />
          <path
            fill="none"
            stroke="hsl(265, 48%, 15%)"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
			M80.023,157.564c0,0,1.337,41.017-6.688,70.441"
          />
          <path
            fill="none"
            stroke="hsl(265, 48%, 15%)"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
			M62.189,201.256c0,0-3.567,20.509-7.579,26.75"
          />
          <path
            fill="none"
            stroke="hsl(265, 48%, 15%)"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
			M40.344,197.689c0,0-2.675,17.387-7.58,28.088"
          />
          <path
            fill="none"
            stroke="hsl(265, 48%, 15%)"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
			M138.428,33.176c0,0-36.558-13.376-76.238,28.979c-39.68,42.354-26.305,140.44-49.488,163.623"
          />
          <path
            fill="none"
            stroke="hsl(265, 48%, 15%)"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
			M141.103,33.176c0,0,32.993-14.268,66.43,18.725c33.437,32.992,31.654,62.863,37.449,71.334"
          />
          <path
            fill="none"
            stroke="hsl(265, 48%, 15%)"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
			M203.521,143.297c0,0,3.566,28.534,16.495,43.693"
          />

          <polygon
            fill="none"
            stroke="hsl(265, 48%, 15%)"
            stroke-width="7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            points="
			211.769,182.308 210.885,192.784 218.309,184.823 		"
          />
        </g>
      </g>
    </svg>
  );
};
