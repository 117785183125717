export const ColourPaletteBuilderIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_2"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="100px"
      height="100px"
      viewBox="0 0 100 100"
      enable-background="new 0 0 100 100"
    >
      <g>
        <g id="face">
          <path
            fill="#1E1E28"
            stroke="#1E1E28"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
			M44.54,77.932c-1.221-0.542-5.379,3.491,4.303,5.523c8.965-0.479,8.368-5.882,5.739-5.523
			C51.951,78.291,45.616,78.41,44.54,77.932z"
          />

          <line
            fill="none"
            stroke="#1E1E28"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="35.216"
            y1="71.478"
            x2="35.216"
            y2="75.661"
          />

          <line
            fill="none"
            stroke="#1E1E28"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            x1="62.828"
            y1="71.478"
            x2="62.828"
            y2="75.661"
          />

          <path
            fill="#1E1E28"
            stroke="#1E1E28"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
			M32.348,79.486c-4.092-0.944-5.021,2.2-5.021,3.969s0.957,4.643,4.631,4.404c3.258-0.484,5.079-1.448,4.573-4.404
			C35.934,79.964,33.902,79.845,32.348,79.486z"
          />

          <path
            fill="#1E1E28"
            stroke="#1E1E28"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="
			M65.938,79.313c-2.15-0.196-4.305,2.642-4.424,4.275c-0.119,1.635,0.479,5.46,4.424,4.981c3.943-0.478,4.541-1.685,4.9-4.607
			S69.283,79.618,65.938,79.313z"
          />
        </g>
        <path
          fill="none"
          stroke="#000000"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M25.175,62.751c0,0,0.12,13.148,0,14.345c-0.119,1.195-12.503,16.615,11.439,19.604c23.944,2.988,35.18-1.315,38.168-2.511
		s0.357-14.084,0-16.306c-0.359-2.222-1.195-14.893-1.436-15.132C73.109,62.512,25.175,62.751,25.175,62.751z"
        />

        <path
          fill="#1E1E28"
          stroke="#1E1E28"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M86.775,45.777c-9.723-14.504-20.809-19-37.332-18.786c-9.073,0-12.873-2.891-25.305,5.716
		C11.708,41.314,5.014,54.383,6.448,74.942c1.189,17.05,14.11,21.162,31.301,21.889c-0.377-0.043-0.75-0.084-1.134-0.132
		c-23.943-2.988-11.559-18.408-11.439-19.604c0.12-1.196,0-14.345,0-14.345s47.934-0.239,48.172,0
		c0.24,0.239,1.076,12.91,1.436,15.132c0.357,2.222,2.988,15.11,0,16.306c-0.867,0.347-2.43,0.954-4.789,1.569
		c8.012-1.048,12.207-3.235,15.506-5.833C96.02,81.638,96.498,60.282,86.775,45.777z"
        />

        <path
          id="paint-top"
          fill="#9E87DE"
          stroke="#1E1E28"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M53.027,5.613c-3.103-3.756-4.901-5.259-8.128,0.359c-4.508,7.848-18.302,21.823-23.07,24.266
		c-6.535,3.347-1.001,12.773,5.14,7.052c8.727-8.128,0.479,1.793,9.443,5.618c6.51,2.777,8.329-4.62,9.563-6.933
		c0.957-1.793,3.564-2.869,4.492-0.239c0.955,2.71,3.518,7.921,7.449,7.729c6.832-0.333,5.713-4.743,5.391-8.925
		c-0.238-3.108,0.119-5.26,5.379,0.837c5.307,6.15,12.322,4.821,10.52-0.717C77.557,29.596,57.234,10.707,53.027,5.613z"
        />
      </g>
    </svg>
  );
};
