export const PomodoroIcon = () => {
  return (
    <svg
      version="1.1"
      id="tomato"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="100px"
      height="100px"
      viewBox="0 0 100 100"
      enable-background="new 0 0 100 100"
    >
      <g>
        <path
          fill="#FF525A"
          stroke="#4B3634"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M49.967,28.355C39.879,28.752,14.772,27.282,5.33,50.458c-9.441,23.179,12.661,32.403,15.666,33.264
		c3.004,0.856,39.27,6.438,56.867,0S98.68,61.48,96.318,50.391C93.958,39.299,77.22,27.282,49.967,28.355z"
        />

        <path
          fill="#007F33"
          stroke="#4B3634"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
		M53.686,17.303c-0.858-1.696-3.719-8.69-6.938,0.752c-2.575,6.438-1.501,6.223-2.575,6.438c-1.073,0.214-9.014-1.288-10.729-2.147
		c-1.718-0.858-7.513,0.43-2.575,4.723c4.934,4.291,9.44,4.291,8.368,5.149c-1.073,0.859-4.078,3.434-4.722,6.652
		s9.014,3.218,14.378-2.574c5.51-5.006,0.574,2.54,14.593,4.736c6.654,1.273,0.218-8.385,0-9.458
		c-0.215-1.072,4.615-1.501,8.585-6.652c3.566-7.198-10.731-1.073-14.381-0.214C55.883,21.115,55.007,19.699,53.686,17.303z"
        />
      </g>
    </svg>
  );
};
