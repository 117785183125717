import { projects as projectsImport } from "../../contents/projects";
import { NavLink } from "react-router-dom";
import "./ProjectCards.css";
import { ColourPaletteBuilderIcon } from "../../svg/ColourPaletteBuilderIcon";
import { PomodoroIcon } from "../../svg/PomodoroIcon";
import { Circle } from "../../svg/Circle";

const getThumbnailIcon = (projectName) => {
  if(projectName === 'Colour Palette Builder'){
    return <ColourPaletteBuilderIcon />
  } else if (projectName === 'Pomodoro') {
    return <PomodoroIcon />
  } else {
    return <Circle />
  }
}

const ProjectCard = ({ project }) => {
  return (
    <>
      <div className="project-card">
        <div className="project-card-image-thumbnail">
        {getThumbnailIcon(project.title)}

        </div>
        <div className="project-card-text">
          <h2 className="project-card-title">{project.title}</h2>
          <p className="project-card-technology">{project.technology}</p>
          <p className="project-card-summary">{project.heading}</p>
        </div>
      </div>
    </>
  );
};

const ProjectCards = () => {
  const projects = projectsImport;
  return (
    <div className="content projects-cards-div">
      <ul className="project-cards">
        {projects.map((project, index) => {
          return (
            <li className="project-card-list-item" key={index}>
              <NavLink
                onClick={() => window.scrollTo(0, 0)}
                to={`/projects/${project.url}`}
              >
                <ProjectCard project={project} />
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export const ProjectsPage = () => {
  return <ProjectCards></ProjectCards>;
};
