export const Icon = () => {
  return (
    <svg
      version="1.1"
      id="icon_1_"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="36px"
      height="36px"
      viewBox="0 0 100 100"
      enable-background="new 0 0 100 100"
    >
      <g id="icon">
        <path
          fill="none"
          stroke="#BF9CDE"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
          M45.359,67.938c0,0,4.641,6.552,9.75,0"
        />

        <line
          fill="none"
          stroke="#BF9CDE"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          x1="37.59"
          y1="58.646"
          x2="37.59"
          y2="63.674"
        />

        <line
          fill="none"
          stroke="#BF9CDE"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          x1="63.029"
          y1="58.646"
          x2="63.029"
          y2="63.674"
        />
        <path
          fill="none"
          stroke="#BF9CDE"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
          M50.404,38.082c-3.065,3.352-14.948,14.929-22.717,15.69c-0.151,3.442,0.304,3.81,0,8.685c-0.305,4.875-4.417,15.385,5.18,19.802
          c17.975,2.028,20.423,2.481,35.339,0c5.913-2.066,6.097-7.159,6.097-10.967c0-1.675-1.372-7.314-1.223-8.378
          c0.154-1.066-0.911-5.182-0.605-9.444C63.184,49.204,54.887,43.661,50.404,38.082z"
        />

        <path
          fill="#BF9CDE"
          stroke="#BF9CDE"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          d="
          M50.404,16.146C-4.607,13.1,2.705,81.343,4.076,81.497s28.792,0.761,28.792,0.761c-9.597-4.417-5.485-14.927-5.18-19.802
          c0.304-4.875-0.151-5.242,0-8.685c7.769-0.761,19.651-12.338,22.717-15.69c4.482,5.579,12.779,11.122,22.071,15.387
          c-0.306,4.263,0.76,8.378,0.605,9.444c-0.149,1.063,1.223,6.703,1.223,8.378c0,3.607-0.174,8.36-5.213,10.612H69.1
          c3.762-0.821,12.057-0.407,27.9-0.561C97,48.747,95.172,14.471,50.404,16.146z"
        />
      </g>
    </svg>
  );
};
