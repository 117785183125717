import cpbVideo from "../assets/colour-palette-builder-video.mov";
import cpbVideoStill from "../assets/colour-palette-builder-video-still.png";

import pomodoroVideo from "../assets/pomodoro-video.mov";
import pomodoroVideoStill from "../assets/pomodoro-video-still.png";

export const colourPaletteBuilder = {
  title: "Colour Palette Builder",

  url: "colour-palette-builder",

  links: {
    prod: "https://colour-palette-builder.juliennecodes.com",
  },

  heading:
    "App for collecting colours",

  summary: [
    "Colour Palette Builder was primarily built to keep a record of colours I often use.",
    "I tailored the app around my usage. The main goal was to have a central place where I can collect colours. I wanted to easily copy the colours so I can use them whether picking colours for art apps or for styling in web development projects.",
  ],

  technology: "React Frontend and Express Backend",

  video: {
    video: cpbVideo,
    poster: cpbVideoStill,
  },
};

export const pomodoro = {
  title: "Pomodoro",

  url: "pomodoro",

  links: {
    prod: "https://pomodoro.juliennecodes.com",
  },

  heading: "Time management tool for maximizing productivity",

  summary: [
    "My goal in Pomodoro was to write a complete working app. I chose Pomodoro because I use pomodoro timers to help with my productivity. In this project, I made my ideal pomodoro timer.",
  ],

  technology: "React",

  video: {
    video: pomodoroVideo,
    poster: pomodoroVideoStill,
  },
};

export const projects = [colourPaletteBuilder, pomodoro];
